@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
.fs-2rem {
  font-size: 2rem !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* .ql-editor {
  background: transparent !important;
} */
